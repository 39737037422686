/* Enhanced text contrast for clarity */
.bug-detailed-view {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bug-detailed-view h2 {
  color: #333;
  font-family: 'Creepster', cursive;
}

.bug-detailed-view img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.bug-detailed-view h3 {
  color: #444;
  margin-top: 20px;
  font-family: 'Share Tech Mono', monospace;
}

.bug-detailed-view p {
  color: #555;
  font-family: 'Share Tech Mono', monospace;
}

.bug-detailed-view ul {
  list-style-type: none;
  padding-left: 0;
}

.bug-detailed-view ul li {
  margin-bottom: 10px;
  color: #444;
  font-family: 'Share Tech Mono', monospace;
}