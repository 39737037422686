.bug-of-the-day {
  background: linear-gradient(to bottom, #ff7e5f, #feb47b);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #ffffff;
  font-family: 'Creepster', cursive;

  /* Adding fade-in animation */
  animation: fade-in 1s ease-in-out;
}

.bug-of-the-day img {
  border-radius: 100%;
  border: 5px solid #fff;
  width: 200px;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .bug-of-the-day img {
    width: 150px;
    height: 150px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}