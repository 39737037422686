/* Updated App-level styles */
.App {
  text-align: center;
  background: var(--background-gradient);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Creepster', cursive;
  color: var(--font-color);
  transition: background-color 0.3s, color 0.3s, background 0.3s;
}

.App-header {
  background: url('https://images.pexels.com/photos/267355/pexels-photo-267355.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: 2px 2px 4px #000000;
  transition: background 0.3s ease;
}

@media (max-width: 768px) {
  .App-header {
    font-size: calc(8px + 2vmin);
    padding: 10px;
  }
}

/* Enhanced logo animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear; /* added animation */
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.App-logo:hover {
  transform: scale(1.05);
}

.App-link {
  color: #ffd700;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.App-link:hover {
  color: #ffef99;
}

/* Ensure consistent body margin */
body {
  margin: 0;
}