.random-bug-generator {
  background: linear-gradient(to right, #1e3c72, #2a5298);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  text-align: center;

  /* Adding fade-in animation */
  animation: fade-in 1s ease-in-out;
}

.random-bug-generator button {
  padding: 10px 20px;
  background-color: #ff6f61;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.random-bug-generator button:hover {
  background-color: #ff8566;
  color: #ffffff;
  transform: scale(1.05);
}

.random-bug-generator img {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .random-bug-generator img {
    width: 80%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}