.bug-glossary {
  background-color: #002635;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  /* Adding entrance animation */
  animation: entrance 1s ease-in-out;
}

.bug-glossary h1 {
  color: #ffeb3b;
  font-family: 'Creepster', cursive;
}

.bug-glossary ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.bug-glossary ul li {
  flex: 1 1 calc(25% - 40px);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  color: #ffeb3b;
  font-family: 'Share Tech Mono', monospace;
}

.bug-glossary ul li:hover {
  transform: scale(1.05);
}

.bug-glossary img {
  width: 100px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .bug-glossary ul li {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .bug-glossary ul li {
    flex: 1 1 100%;
  }
}

@keyframes entrance {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}