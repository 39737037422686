/* Adjusted navbar styling for better readability */
.navbar {
  background: linear-gradient(to right, #1e3c72, #2a5298);
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.navbar-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.navbar-links li {
  display: inline-block;
  margin: 0 10px;
  transition: transform 0.3s ease;
  list-style-type: none;
}

.navbar-links li:hover {
  transform: translateY(-5px);
}

.navbar-links a {
  color: #ffffff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background: #ff7e5f;
  transition: background 0.3s, transform 0.3s;
}

.navbar-links a:hover {
  background: #ff8566;
  color: #ffffff;
  transform: scale(1.05);
}

.dark-mode-toggle {
  padding: 0.5rem 1rem;
  background: #4ca1af;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dark-mode-toggle:hover {
  background: #3b97a3;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .navbar-links li {
    margin: 0 5px;
  }
}

@media (max-width: 480px) {
  .navbar-links {
    flex-direction: column;
    align-items: center;
  }

  .navbar-links li {
    margin: 5px 0;
  }
}

/* Adding fade-in animation to navbar links */
.navbar-links a {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}