/* Styling for the Quiz Component */
.quiz-container {
  background: linear-gradient(to bottom, #ffe680, #ff9900);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Share Tech Mono', monospace;
}

.quiz-container h1 {
  color: #333;
  font-family: 'Creepster', cursive;
}

.quiz-container .question {
  margin: 20px 0;
  color: #444;
}

.quiz-container .options {
  list-style-type: none;
  padding: 0;
}

.quiz-container .options li {
  margin-bottom: 10px;
}

.quiz-container .options button {
  padding: 10px 20px;
  background-color: #ff7043;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.quiz-container .options button:hover {
  background-color: #ff8566;
  transform: scale(1.05);
}

.quiz-container .result {
  margin-top: 20px;
  font-size: 1.2em;
  color: #ddf;
}