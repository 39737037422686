/* Enhanced text contrast for better readability on the home page */
.home-page {
  background: linear-gradient(to right, #002635, #00494d);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  text-align: center;
  font-family: 'Creepster', cursive;

  /* Adding fade-in animation */
  animation: fade-in 1s ease-in-out;
}

.home-page h1 {
  font-size: 3em;
  text-shadow: 2px 2px 4px #000000;
  transition: color 0.3s ease;
  color: #FFFF00; /* Enhance text color for better contrast */
}

.home-page h1:hover {
  color: #FFD700;
}

.home-page p {
  color: #ddf;
  font-family: 'Share Tech Mono', monospace;
}

.home-page img.home-image {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.ascii-logo {
  color: #ffffff;
  font-family: 'Share Tech Mono', monospace;
  text-shadow: 1px 1px 2px #000;
  margin-bottom: 20px;
}

.festive-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.festive-section h2 {
  font-size: 2em;
  text-shadow: 2px 2px 4px #000000;
  margin-top: 20px;
  color: #ffe680;
  transition: color 0.3s ease;
}

.festive-section h2:hover {
  color: #ffd740;
}

.festive-section p {
  font-size: 1.2em;
  margin-top: 10px;
  max-width: 600px;
  color: #ddf;
  font-family: 'Share Tech Mono', monospace;
}

.code-of-honour {
  margin-top: 40px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.code-of-honour h2 {
  font-size: 2em;
  color: #ffd700;
  margin-bottom: 20px;
  font-family: 'Creepster', cursive;
}

.code-of-honour p {
  font-size: 1.2em;
  color: #ddf;
  margin: 10px 0;
  font-family: 'Share Tech Mono', monospace;
}

@media (max-width: 768px) {
  .home-page h1 {
    font-size: 2.5em;
  }

  .festive-section h2 {
    font-size: 1.5em;
  }

  .code-of-honour h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .home-page h1 {
    font-size: 2em;
  }

  .festive-section h2 {
    font-size: 1.2em;
  }

  .code-of-honour h2 {
    font-size: 1.2em;
  }

  .code-of-honour p {
    font-size: 0.9em;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}