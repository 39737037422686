/* Styling for Bug Party Corner */
.bug-party-container {
  padding: 20px;
  text-align: center;
  background: linear-gradient(to right, #ffb347, #ffcc33);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.party-text {
  font-size: 2em;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.bug-image {
  max-width: 150px;
  margin: 10px;
  animation: spin 7s linear infinite;
}