body {
  margin: 0;
  font-family: 'Creepster', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--font-color);
  transition: background-color 0.3s, color 0.3s;
}

code {
  font-family: 'Share Tech Mono', monospace, source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

:root {
  --background-color: #ffffff;
  --font-color: #000000;
  --background-gradient: linear-gradient(to left, #ffecd2, #fcb69f);
}

body.dark-mode {
  --background-color: #121212;
  --font-color: #ffffff;
  --background-gradient: linear-gradient(to left, #2c3e50, #4ca1af);
}

/* Enhanced to support smooth dark mode toggling */
.dark-mode-toggle {
    display: flex;
    align-items: center;
    background: #4ca1af;
    padding: 10px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

dark-mode-toggle:hover {
    background: #3b97a3;
    color: #fff;
}